import React from "react";
import { graphql } from "gatsby";

import { Section } from "../components/elements";
import Layout from "../components/layouts/Layout";

const AboutPage = ({ data }) => {
  const node = data.allWpPage.edges[0].node;
  return (
    <Layout
      internal={true}
      title={node.pageFields.seoTitle}
      description={node.pageFields.seoDescription}
    >
      <Section>
        <h2>{node.title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: node.content,
          }}
        ></div>
      </Section>
    </Layout>
  );
};

export default AboutPage;

export const AboutPageQuery = graphql`
  query AboutPageQuery {
    allWpPage(filter: { slug: { eq: "about" } }) {
      edges {
        node {
          id
          title
          content
          pageFields {
            seoTitle
            seoDescription
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1000, layout: FIXED)
                }
              }
            }
          }
        }
      }
    }
  }
`;
